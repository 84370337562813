export default {
  symbol: {
    '?': '？',
    ':': '：',
    ',': '，',
    '!': '！'
  },
  common: {
    ok: '确定',
    cancel: '取消',
    success: '成功',
    fail: '失败',
    uploadImg: '上传图片',
    theme: '主题',
    license: '密钥',
    save: '保存',
    detail: '详情',
    copy: '复制',
    edit: '编辑',
    delete: '删除',
    remind: '提示',
    name: '名称',
    rename: '重命名',
    show: '显示',
    hidden: '隐藏',
    open: '打开',
    close: '关闭',
    group: '分组',
    import: '导入',
    export: '导出',
    Select: '全选',
    moveOut: '移出',
    cancelledDeletion: '已取消删除',
    paginationTotal: '共 {num} 条',
    createSuccess: '创建@:common.success',
    createFail: '创建@:common.fail',
    uploadSuccess: '上传@:common.success',
    uploadFail: '上传@:common.fail',
    modifySuccess: '修改@:common.success',
    modifyFail: '修改@:common.fail',
    copySuccess: '已@:common.copy',
    copyFail: '@:common.copy@:common.fail',
    enableSuccess: '启用@:common.success',
    enableFail: '启用@:common.fail',
    deleteSuccess: '删除@:common.success',

    deleteFail: '删除@:common.fail',
    addSuccess: '新增@:common.success',
    addFail: '新增@:common.fail',
  },
  remind: {
    inputRemind: '请输入',
    selectRemind: '请选择',
    searchRemind: '输入关键字搜索',
    selectGroupRemind: '请选择分组',
    rermanentDeletionRemind: '此操作将永久删除, 是否继续?',
    passwordRegulationError: '请输入字母、数字、特殊字符任意两种， 最少8位!'
  },
  account: '账号',
  password: "密码",
  signIn: '登录',
  loggingIn: '登录中',
  logginErr: '账号或密码错误',
  register: '注册新账号',
  findPassword: '忘记密码',
  accountNullError: '请输入账号',
  passwordNullError: '请输入密码',
  scene: '场景',
  organization: '组织',
  home: "首页",
  homeMenu: {
    homePage: "主页",
    myWorkSpace: "我的工作空间"
  },
  homeBench: {
    myFlow: "我的流程",
    myCollect: "我的收藏",
    recentUse: "最近使用",
    my: "我的",
    allWorkSpace: "全部工作空间",
    workSpace: "工作空间",
    app: "应用",
    myToDoList: "我的待办",
    iInitiated: "我发起的",
    completed: "已完成",
    createApp: "新建应用",
    sort: "分类排序",
    cardView: "卡片视图",
    listView: "列表视图",
    tableView: "表格视图",
  },
  homeBenchRecent: {
    TheLastDay: "最近一天",
    TheLastWeek: "最近七天",
    TheLastMonth: "近三十天"
  },
  userMenu: {
    account: '个人账户',
    system: '组织管理',
    logout: '安全退出'
  },
  footMenu: {
    category: '分类管理',
    store: '应用中心',
    theme: '主题配置',
    universe: 'Universe'
  },
  appTypeManagement: '应用类型管理',
  appType: '应用类型',
  modifyAppTypeName: '修改应用类型名称',
  import: '导入',
  createScene: '新建场景',
  createWorkSpace: "新建空间",
  sceneMoreMenu: {
    auth: '权限',
    edit: '修改',
    preview: '预览',
    acquire: '获取',
    export: '导出',
    delete: '删除',
    path: '路径',
    collect: "收藏",
    import: '导入',
    addWorkSpace: "添加工作空间",
  },
  sceneShowType: {
    edit: '编辑',
    published: '已发布',
    unpublish: '未发布',
    table: {
      index: '序号',
      title: '项目名称',
      name: '项目名称',
      protocol: '协议',
      username: '发布者',
      status: '项目状态',
      action: '',
      type: '类型',
      desc: '描述',
      url: '服务地址'
    }
  },
  copyPathModal: {
    scenePath: '场景路径',
    url: 'URL'
  },
  modifyModal: {
    modifyInfo: '修改信息',
    orgCategory: '组织',
    category: '分组',
    title: '场景名称',
    password: '密码',
    theme: '主题',
    status: '发布状态',
    published: '发布',
    unpublished: '未发布',
    categoryNullError: '请选择@:modifyModal.category',
    titleNullError: '请设置@:modifyModal.title',
    spaceTitleNullError: '请设置@:modifyModal.spaceTitle',
    ThemeIcon: '图标背景',
    spaceTitle: '空间名称',
  },
  accountInfo: {
    safe: '安全管理',
    phone: '手机',
    email: '邮箱',
    password: '密码',
    modify: '修改',
    unbind: '未绑定',
    modifyPassword: '修改密码',
    oldPassword: '旧密码：',
    newPassword: '新密码：',
    confirmPassword: '确认密码：',
    oldPasswordNullError: '请输入旧密码',
    newPasswordNullError: '请输入新密码 | 长度在 6 到 12 个字符',
    confirmPasswordNullError: '请确认密码 | 长度在 6 到 12 个字符',
  },
  systemInfo: {
    systemHome: '首页',
    tenant: '租户管理',
    apk: 'APK',
    license: '密钥管理',
    dept: '部门管理',
    job: '职位管理',
    role: '角色管理',
    user: '用户管理',
    theme: '主题管理',
    finance: '财务管理',
    licenseOverdueMessage: 'License已过期，请重新申请！',
    tryOut: '试用',
    remain: '剩余',
    year: '年',
    month: '月',
    day1: '天',
    day2: '日',
    expires: '到期',
    logo: 'LOGO',
    uploadLogo: '上传Logo',
    uploadImg: '上传图片',
    loginBg: '登录背景',
    name: '名称',
    dominFiling: '域名备案信息',
    light: '简约白',
    dark: '科技黑',
    addLicense: '添加秘钥',
    serverId: '服务器Id',
    notFoundServer: '未找到服务器id',
    activated: '生效中',
    notActivated: '未生效',
    licenseColumns: {
      index: '序号',
      status: '状态',
      startTime: '开始时间',
      endTime: '到期时间',
    },
    addLicenseRemind: '请输入您的密钥',
    addJob: '添加职位',
    jobName: '职位名称',
    addDept: '添加部门',
    addSubDept: '添加子部门',
    editDept: '编辑部门',
    deleteDeptRemind: '是否确认删除该部门?',
    deptName: '部门名称',
    deptHeadUser: '部门负责人',
    deptInfo: '部门介绍',
    deptState: '状态',
    deptStartTime: '开始时间',
    deptEndTime: '结束时间',
    deptLon: '经度',
    deptlat: '维度',
    deptTable: {
      index: '序号',
      userName: '账号',
      userAlia: '用户名称',
      userRole: '用户角色',
      userState: '用户状态 ',
      action: '',
      jobs: '职位',
    },
    roleInfo: {
      addRole: '添加角色',
      edit: '修改',
      delete: '刪除',
      deleteRemind: '此操作将永久删除, 是否继续?',
      index: '序号',
      roleName: '角色名称',
      userSize: '用户数量',
      action: '',
    },
    userInfo: {
      batchAddUsers: '批量添加用户',
      addUser: '添加用户',
      modifyUserInformation: '修改用户信息',
      edit: '修改',
      reset: '重置密码',
      delete: '删除',
      index: '序号',
      userName: '账号',
      password: '密码',
      userAlia: '用户名称',
      userDeptNames: '部门',
      jobs: '职位',
      userJobs: '用户职位',
      userRole: '用户角色',
      userState: '用户状态',
      action: '',
      downloadTemplate: '下载模板',
      downloadTmeplateRemind: '请下载模板，按格式修改后导入',
      downloadRemind: '将文件拖到此处，或',
      clickUpload: '点击上传',
      selectDeptRemind: '选择部门',
      userNameNullError: '@:remind.selectRemind @:systemInfo.userInfo.userName',
      passwordNullError: '@:remind.selectRemind @:systemInfo.userInfo.password',
      userAliaNullError: '@:remind.selectRemind @:systemInfo.userInfo.userAlia',
      userRoleNullError: '@:remind.selectRemind @:systemInfo.userInfo.userRole',
      userStateNullError: '@:remind.selectRemind @:systemInfo.userInfo.userState',
      userDeptsNullError: '@:remind.selectRemind @:systemInfo.userInfo.userRole',
      addDeptRemind: '请先添加部门！',
      resetPasswordRemind: '此操作将重置该用户密码, 是否继续?',
      resetPasswordSuccessRemind: '重置成功，密码重置为',
      resetPasswordFailRemind: '重置失败',
      cancelledResetPasswordRemind: '已取消重置密码'
    },
    themeInfo: {
      theme: '主题',
      layout: '布局',
      themeSetting: '主题设置',
      themeName: '主题名称',
      page: '页面',
      pageHeader: '页面头部',
      pageTheme: '页面主题',
      table: '表格',
      card: '卡片',
      customPage: '自定义页面',
      customPageChart: '自定义页面图表',
      otherSetting: '其他设置',
      tableHeader: '页面头部背景',
      logoHeader: 'logo背景',
      navHeader: '顶部导航背景',
      leftListTitle: '左侧列表背景'
    }
  },
  selectUserModal: {
    title: '选择人员',
    placeholder: '请输入平台名称'
  },
  selectDepartmentModal: {
    title: '选择部门',
    placeholder: '请输入平台名称'
  },
  auth: {
    Description: '你是此应用的管理员，可以配置应用，角色和成员',
    getAppID: '获取应用ID',
    title: '应用管理员',
    desc: '可以配置应用,管理应用下所有数据和人员',
    unit: '人',
    AppAdministrator: '管理员',
    Owner: '拥有者',
    OtherRole: '角色',
    PageRole: '快捷登录',
    RolePermissionSettings: '角色权限设置',
    addRole: '添加角色',
    updateRole: '修改角色',
    authInfo: {
      deleteRemind: '此操作将移除这些用户, 是否继续?',
      renewSuccess: '更新@:common.success',
      renewFail: '更新@:common.fail',
      copyRemindSuccess: '已复制！',
      copyRemindFail: '复制失败！',
    },
    // 管理员
    adminRole: {
      PersonnelRemoval: '人员移除',
      Department: '添加部门',
      AddPeople: '添加人员'
    },
    adminRoleTalbe: {
      index: '序号/全选',
      name: '名称',
      role: '用户角色',
      type: '类型',
      department: '部门',
      addTime: '加入时间',
      operation: ''
    },
    typeCollection: {
      user: '用户',
      department: '部门'
    },
    // 角色
    role: {
      title: '+ 添加角色',
      desc: '添加角色设置应用权限，控制用户可见的应用',
    },
    roleTalbe: {
      roleName: '角色名称',
      desc: '描述',
      userNum: '人员数量',
      operation: ''
    },
    // 快捷登录
    quickLogin: {
      title: '快捷登录',
      desc: '可以管理使用该应用作为快捷登录页面的人员',
      PersonnelRemoval: '人员移除',
      Department: '添加部门',
      AddPeople: '添加人员'
    },
    quickLoginTalbe: {
      index: '序号/全选',
      name: '名称',
      type: '类型',
      department: '部门',
      addTime: '加入时间',
      operation: ''
    },
    copyAppId: {
      title: '应用ID',
      name: '应用id',
      copy: '复制'
    },
    moreMenuList: {
      edit: '修改',
      CopyUser: '复制角色',
      roleUsers: '查看人员',
      addUser: '添加人员',
      addDepartment: '添加部门',
      delete: '删除'
    },
    appRoleAuthorityModal: {
      name: '角色名称',
      desc: '描述',
      permissions: '应用权限',
      gateway: '应用门户 :',
      formDesign: '表单设计 :',
      AllForms: '所有表单权限',
      SelectableForms: '可选择的表单权限'
    },
    sceneRoleAuthorityModal: {
      name: '角色名称',
      desc: '描述',
      permissions: '应用权限',
      gateway: '应用门户 :',
      build: '应用搭建 :',
      Modeling: '场景建模 :',
      develop: '在线开发 :'
    },
    fileRoleAuthorityModal: {
      name: '角色名称',
      desc: '描述',
      permissions: '应用权限',
      gateway: '应用门户 :',
      build: '应用搭建 :',
      fileJurisdiction: '文件权限 :'
    }
  },
  globalSearch: {
    placeholder: '搜索...',
    noData: '暂无数据',
    unfold: '展开',
    fold: '收起',
  },
  appStore: {
    title: '应用市场',
    install: '安装',
    RequestFailed: '请求失败',
    ConfirmInstallation: '是否确认安装',
    SuccessfullyInstalledTo: '已成功安装到',
    DefaultClassification: '默认分类',
    InstallationFailure: '安装失败，请联系管理员！',
    NoPermissions: '暂无权限，请联系管理员！',
  }
}