<template>
    <div class="portal-system-tenant">
        <div class="portal-system-tenant-wrapper">
            <div class="portal-system-tenant-header">
                <div class="portal-system-tenant-header-title">
                    租户管理
                </div>
                <div>
                    <cgs-button ghost class="portal-system-tenant-add-tenant" @click="appTenantList">添加租户</cgs-button>
                </div>
            </div>
            <div class="portal-system-tenant-content">
                <div class="portal-system-tenant-content-table">
                    <tenantTable :moreMenuList="moreMenuList" :tableData="showTableData" @clikMenuItem="clikMenuItem"
                        @searchInput="searchInput" @handleChangeState="handleChangeState">
                    </tenantTable>
                </div>
                <div class="portal-system-tenant-content-footer">
                    <cgs-pagination v-model:current="state.currentPagination.current"
                        v-model:pageSize="state.currentPagination.pageSize" show-quick-jumper show-size-changer
                        :total="state.currentPagination.total" :show-total="total => `共${total}条`" />
                </div>
            </div>
        </div>
        <editTenantMsg :dialogUpdateVisible="state.dialogUpdateVisible" :form_TenantInfo="state.form_TenantInfo"
            @cancelEditTenant="state.dialogUpdateVisible = false" @editTenantOK="editTenantOK"></editTenantMsg>
        <rechargeTenant :dialogVisiblerecharge="state.dialogVisiblerecharge" :userBalance="state.userBalance"
            @cancelRecharge="state.dialogVisiblerecharge = false" @rechargeOK="rechargeOK"></rechargeTenant>
        <addUpdataTenant :dialogVisible="state.dialogVisible" :submitState="state.submitState"
            @cancelAddTenantModal="state.dialogVisible = false" @formRePassSubmit="formRePassSubmit"></addUpdataTenant>
    </div>
</template>

<script>
import {
    defineComponent,
    reactive,
    getCurrentInstance,
    computed
} from 'vue'
import {
    getTenantList,
    addTenant,
    editTenant,
    deleteTenant,
    getUserById,
    getTenantInfo,
    recharge,
    updateTenantInfo,
    resetUserPassword
} from '@/api/tenant'
import tenantTable from './components/tenant-table.vue'
import addUpdataTenant from './components/addUpdataTenant.vue'
import rechargeTenant from './components/rechargeTenant.vue'
import editTenantMsg from './components/editTenant.vue'

export default defineComponent({
    components: {
        tenantTable,
        addUpdataTenant,
        rechargeTenant,
        editTenantMsg
    },
    props: {},
    setup () {
        let {
            proxy
        } = getCurrentInstance();
        const state = reactive({
            tableData: [],
            submitState: {
                state: 'add',
                scene: {}
            },
            dialogVisible: false,

            // 充值
            userBalance: 0,
            dialogVisiblerecharge: false,
            currentuser: '',

            // 修改租户信息
            dialogUpdateVisible: false,
            form_TenantInfo: {
                version: "企业版",
                beginDate: "",
                endDate: "",
                isTrial: true
            },
            node: {},
            currentPagination: {
                current: 1,
                total: -1,
                pageSize: 20,
            },
        })
        let moreMenuList = [{
            title: '修改',
            key: 'edit',
            icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai'
        },
        {
            title: '修改租户信息',
            key: 'editTenant',
            icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai'
        },
        // {
        //     title: '重置租户密码',
        //     key: 'resetPassword',
        //     icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai'
        // },
        {
            title: '删除',
            key: 'delete',
            icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu'
        },
        {
            title: '充值',
            key: 'recharge',
            icon: 'cgs-portal-iconfont cgs-portal-icon-chongzhi'
        }
        ]
        const getList = () => {
            getTenantList().then((res) => {
                if (res && res.success) {
                    state.tableData = [];
                    for (let i = 0; i < res.data.length; i++) {
                        let user = res.data[i];
                        state.tableData.push({
                            tenantId: user.tenantId,
                            tenantName: user.tenantName,
                            tenantAdminName: user.admin.username,
                            tenantState: user.state,
                        })
                    }
                    state.currentPagination.total = state.tableData.length
                }
            })
        }
        const clikMenuItem = (scene, params) => {
            if (params.key === 'edit') {
                let data = scene
                state.submitState = {
                    scene: data,
                    state: 'updata'
                }
                state.dialogVisible = true;
            } else if (params.key === 'delete') {
                proxy.$confirm({
                    title: '提示',
                    content: '此操作将永久删除, 是否继续?',
                    onOk () {
                        deleteTenant({
                            "tenantId": scene.tenantId
                        }).then((res) => {
                            if (res && res.code === 200) {
                                proxy.$message.success("删除成功!")
                                getList();
                            } else {
                                proxy.$message.error("删除失败！")
                            }
                        }).catch((res) => {
                            proxy.$message.error(res.msg)
                        })
                    },
                    onCancel () {
                        proxy.$message.info('已取消删除')
                    },
                });
            } else if (params.key === 'recharge') {
                getTenantInfo({
                    tenantId: scene.tenantId
                }).then((res) => {
                    state.userBalance = res.data.account.balance
                    state.dialogVisiblerecharge = true
                    state.currentuser = scene.tenantId
                })
            } else if (params.key === 'editTenant') {
                getTenantInfo({
                    tenantId: scene.tenantId
                }).then((res) => {
                    if (res && res.success) {
                        state.dialogUpdateVisible = true;
                        state.form_TenantInfo = res.data.account;
                        state.submitState.scene = scene;
                    }
                })
            } else if (params.key === 'resetPassword') {
                proxy.$confirm({
                    title: "提示",
                    content: "确认要重置租户管理员密码吗？",
                    onOk () {
                        resetUserPassword({
                            userId: scene.tenantId,
                            newPassword: `${scene.tenantId}123456&`,
                            confirmPassword: `${scene.tenantId}123456&`,
                        })
                            .then((res) => {
                                if (res && res.code === 200) {
                                    proxy.$message.success(
                                        "密码重置成功！新密码为：" + `${scene.tenantId}123456&`
                                    );
                                } else {
                                    proxy.$message.error("重置密码失败");
                                }
                            })
                            .catch((res) => {
                                proxy.$message.error(res.msg);
                            });
                    },
                    onCancel () {
                        proxy.$message.info("重置密码失败");
                    },
                });
            }
        }
        const rechargeOK = (values) => {
            if (Number(values.rechargeBalance)) {
                recharge({
                    tenantId: state.currentuser,
                    amount: Number(values.rechargeBalance)
                })
                state.dialogVisiblerecharge = false
                proxy.$message.success("充值成功!")
            } else {
                proxy.$message.error('充值失败')
            }
        }
        const formRePassSubmit = (values) => {
            state.dialogVisible = false;
            if (state.submitState.state === "add") {
                getUserById({
                    username: values.tenantName
                }).then((res) => {
                    if (res && res.success) {
                        proxy.$message.error("账号已存在，请重新添加！");
                        state.dialogFormVisible = true;
                    } else {
                        let data = {
                            "tenantName": values.tenantName,
                            "username": values.tenantUserName,
                            "password": values.tenantPassword
                        }
                        addTenant(data).then((res) => {
                            if (res && res.success) {
                                proxy.$message.success("添加成功！")
                                getList();
                            } else {
                                proxy.$message.error("添加失败！")
                            }
                        })
                    }

                })
            } else if (state.submitState.state === "updata") {
                let row = values;
                let data = {
                    "tenantId": row.tenantId,
                    "tenantName": row.tenantName
                }
                editTenant(data).then((res) => {
                    if (res && res.success) {
                        proxy.$message.success("修改成功！")
                        getList();
                    } else {
                        proxy.$message.error("修改失败！")
                    }
                })
            }
        }
        const handleChangeState = (state, column) => {
            let data = {
                "state": state,
                "tenantId": column.tenantId
            }
            editTenant(data).then((res) => {
                if (res && res.success) {
                    proxy.$message.success("修改成功！")
                    getList();
                } else {
                    proxy.$message.error("修改失败！")
                }
            })
        }
        const editTenantOK = (values) => {
            updateTenantInfo({
                "account": values,
                "tenantId": state.submitState.scene.tenantId
            }).then((res) => {
                if (res && res.success) {
                    state.dialogUpdateVisible = false;
                    proxy.$message.success("修改成功！");
                } else {
                    proxy.$message.error("修改失败！");
                }
            })
        }
        const appTenantList = () => {
            state.submitState = {
                scene: {},
                state: 'add'
            }
            state.dialogVisible = true;
        }
        getList()
        const searchInput = (value) => {
            state.currentPagination.current = 1
            state.searchInput = value
        }
        const showTableData = computed(() => {
            let _pagination = state.currentPagination
            let data = dataSource.value || []
            return data.filter((item, index) => {
                return index >= (_pagination.pageSize * (_pagination.current - 1)) &&
                    index < _pagination
                        .pageSize * _pagination.current
            })
        })
        const dataSource = computed(() => {
            let searchInput = state.searchInput ? state.searchInput : ''
            let filterData = state.tableData.filter(data => !searchInput || data.tenantId.toLowerCase().includes(
                searchInput.toLowerCase()) || data.tenantName.toLowerCase().includes(searchInput
                    .toLowerCase()) || data.tenantAdminName.toLowerCase().includes(searchInput
                        .toLowerCase()))
            return filterData
        })
        return {
            state,
            dataSource,
            moreMenuList,
            showTableData,
            appTenantList,
            clikMenuItem,
            formRePassSubmit,
            rechargeOK,
            editTenantOK,
            searchInput,
            handleChangeState
        }
    },
})
</script>

<style lang="less" scoped>
@import "~@style/less/theme.less";

.portal-system-tenant {
    width: 100%;
    height: 100%;
    padding: 24px 48px 8px 48px;
    overflow: hidden;
}

.portal-system-tenant-wrapper {
    width: 100%;
    height: 100%;
}

.portal-system-tenant-header {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.portal-system-tenant-header-title {
    .little-title;
    color: @font-color-1;
}

.portal-system-tenant-add-tenant {
    margin-left: 10px;
}

.portal-system-tenant-content {
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
}

.portal-system-tenant-content-table {
    flex: 1;
    height: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.portal-system-tenant-content-footer {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid @border-color;
    margin-top: 4px;
}
</style>