<template>
  <div class="portal-public-view-icon">
    <div class="portal-public-view-icon-wrapper">
      <main-icon-card v-for="item in sceneList" :key="item.id" :ident="item.id" :title="item.title"
        :publish="item.status === 0 ? false : true" :icon="item.icon" :theme-name="item.theme" :appType="item.typeName"
        :editable="hasEditAuth(item)" :more-menu-list="currentMoreMenuList(item)" :isCollect="item.isCollect"
        @clikMenuItem="clikMenuItemHandler(item, $event)" @clickEdit="onClickHandler(item)"
        @clickCollect="clickCollect(item)"></main-icon-card>
    </div>
  </div>
</template>

<script>
import {
  defineComponent
} from 'vue'
import {
  useI18n
} from 'vue-i18n'
import mainIconCard from './main-icon-card.vue'

export default defineComponent({
  components: {
    mainIconCard
  },
  props: {
    sceneList: {
      type: Array,
      default: () => []
    },
    moreMenuList: {
      type: Array,
      default: () => []
    }
  },

  setup (props, ctx) {
    const {
      t
    } = useI18n()
    const clikMenuItemHandler = (scene, params) => {
      ctx.emit('clikMenuItem', scene, params)
    }
    const clickCollect = (scene) => {

      ctx.emit('clikCollect', scene)
    }
    const onClickHandler = (scene) => {
      // 判断是否有编辑权限
      if (scene.appType) {
        hasEditAuth(scene) && ctx.emit('enter', scene)
      } else {
        ctx.emit('enter', scene)
      }
    }
    const hasEditAuth = (scene) => {
      return window.authManager.editSceneAuth(scene.id)
    }
    const towDMoreMenuList = [{
      title: '',
      key: 'auth',
      icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
      disabled: (sceneId) => {
        return !window.authManager.manageAuthSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'edit',
      icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
      disabled: (sceneId) => {
        return !window.authManager.editSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'preview',
      icon: 'cgs-portal-iconfont cgs-portal-icon-yulan',
      disabled: (sceneId) => {
        return !window.authManager.previewSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'export',
      icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
      disabled: (sceneId) => {
        return !window.authManager.exportSceneAuth(sceneId)
      }
    },

    {
      title: '',
      key: 'delete',
      icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
      disabled: (sceneId) => {
        return !window.authManager.deleteSceneAuth(sceneId)
      }
    }
    ]
    const appMoreMenuList = [{
      title: '',
      key: 'auth',
      icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
      disabled: (sceneId) => {
        return !window.authManager.manageAuthSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'edit',
      icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
      disabled: (sceneId) => {
        return !window.authManager.editSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'export',
      icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
      disabled: (sceneId) => {
        return !window.authManager.exportSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'delete',
      icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
      disabled: (sceneId) => {
        return !window.authManager.deleteSceneAuth(sceneId)
      }
    }
    ]
    const IotMoreMenuList = [{
      title: '',
      key: 'auth',
      icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
      disabled: (sceneId) => {
        return !window.authManager.manageAuthSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'edit',
      icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
      disabled: (sceneId) => {
        return !window.authManager.editSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'delete',
      icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
      disabled: (sceneId) => {
        return !window.authManager.deleteSceneAuth(sceneId)
      }
    }
    ]
    let pageMoreMenuList = [{
      title: '',
      key: 'auth',
      icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
      disabled: (sceneId) => {
        return !window.authManager.manageAuthSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'edit',
      icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
      disabled: (sceneId) => {
        return !window.authManager.editSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'preview',
      icon: 'cgs-portal-iconfont cgs-portal-icon-yulan',
      disabled: true
    },
    {
      title: '',
      key: 'export',
      icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
      disabled: true
    },
    {
      title: '',
      key: 'path',
      icon: 'cgs-portal-iconfont cgs-portal-icon-lianjie',
      disabled: false
    },
    {
      title: '',
      key: 'delete',
      icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
      disabled: (sceneId) => {
        return !window.authManager.deleteSceneAuth(sceneId)
      }
    }
    ]
    let vtkMoreMenuList = [{
      title: '',
      key: 'auth',
      icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
      disabled: (sceneId) => {
        return !window.authManager.manageAuthSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'edit',
      icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
      disabled: (sceneId) => {
        return !window.authManager.editSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'preview',
      icon: 'cgs-portal-iconfont cgs-portal-icon-yulan',
      disabled: (sceneId) => {
        return !window.authManager.previewSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'export',
      icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
      disabled: true
    },
    {
      title: '',
      key: 'delete',
      icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
      disabled: (sceneId) => {
        return !window.authManager.deleteSceneAuth(sceneId)
      }
    }
    ]
    let spaceMoreMenuList = [{
      title: '',
      key: 'auth',
      icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
      disabled: (sceneId) => {
        return !window.authManager.manageAuthSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'edit',
      icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
      disabled: (sceneId) => {
        return !window.authManager.editSceneAuth(sceneId)
      }
    },
    {
      title: '',
      key: 'delete',
      icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
      disabled: (sceneId) => {
        return !window.authManager.deleteSceneAuth(sceneId)
      }
    }
    ]
    let ReportMoreMenuList = [
      {
        title: '',
        key: 'auth',
        icon: 'cgs-portal-iconfont cgs-portal-icon-quanxian',
        disabled: (sceneId) => {
          return !window.authManager.manageAuthSceneAuth(sceneId)
        }
      },
      {
        title: '',
        key: 'edit',
        icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai',
        disabled: (sceneId) => {
          return !window.authManager.editSceneAuth(sceneId)
        }
      },
      {
        title: '',
        key: 'export',
        icon: 'cgs-portal-iconfont cgs-portal-icon-daochu',
        disabled: (sceneId) => {
          return !window.authManager.deleteSceneAuth(sceneId)
        }
      },
      {
        title: '',
        key: 'delete',
        icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu',
        disabled: (sceneId) => {
          return !window.authManager.deleteSceneAuth(sceneId)
        }
      }
    ]
    const currentMoreMenuList = (scene) => {
      let _moreMenuList = towDMoreMenuList
      if (scene.appType === 'TwoD' || scene.appType === 'ThreeD' || scene.appType === 'Earth' || scene.appType === 'CAD' || scene.appType === "SCADA" || scene.appType === "NewEarth") {
        _moreMenuList = towDMoreMenuList.map(_item => {
          _item['title'] = t('sceneMoreMenu.' + _item.key)
          return _item
        })
      } else if (scene.appType === 'App' || scene.appType === 'Workflow' || scene.appType === 'GeoModel' || scene.appType === 'Volume') {
        _moreMenuList = appMoreMenuList.map(_item => {
          _item['title'] = t('sceneMoreMenu.' + _item.key)
          return _item
        })
      } else if (scene.appType === 'Iot') {
        _moreMenuList = IotMoreMenuList.map(_item => {
          _item['title'] = t('sceneMoreMenu.' + _item.key)
          return _item
        })
      } else if (scene.appType === 'Static') {
        _moreMenuList = pageMoreMenuList.map(_item => {
          _item['title'] = t('sceneMoreMenu.' + _item.key)
          return _item
        })
      } else if (scene.appType === 'Vtk' || scene.appType === 'LargeScreen' || scene.appType === 'AI') {
        _moreMenuList = vtkMoreMenuList.map(_item => {
          _item['title'] = t('sceneMoreMenu.' + _item.key)
          return _item
        })
      } else if (scene.appType === "Report") {
        _moreMenuList = ReportMoreMenuList.map(_item => {
          _item['title'] = t('sceneMoreMenu.' + _item.key)
          return _item
        })
      } else if (!scene.appType) {
        _moreMenuList = spaceMoreMenuList.map(_item => {
          _item['title'] = t('sceneMoreMenu.' + _item.key)
          return _item
        })
      } else {
        _moreMenuList = IotMoreMenuList.map(_item => {
          _item['title'] = t('sceneMoreMenu.' + _item.key)
          return _item
        })
      }


      let list = _moreMenuList.filter(_item => {
        let _bool = true
        let _disabled = _item.disabled
        if (_disabled instanceof Function) {
          _bool = !_disabled(scene.id)
        } else {
          _bool = !_disabled
        }
        return _bool
      })
      return list
    }
    return {
      clikMenuItemHandler,
      onClickHandler,
      currentMoreMenuList,
      hasEditAuth,
      clickCollect
    }
  },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";

.portal-public-view-icon {
  width: 100%;
  height: 100%;
}

.portal-public-view-icon-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.portal-public-view-icon-card {
  //margin: 5px;
  width: 24.2%;
  margin: 0.4%;
  border-radius: 6px;
  .border();
  background: @card-color;
  overflow: hidden;

  @media screen and (min-width: 1800px) {
    width: 19.2%;
    margin: 0.4%;
  }
}

.portal-public-view-icon-card-cover {
  position: relative;
  overflow: hidden;
}

.portal-public-view-icon-card-cover:hover {
  // filter: blur(3px)
}

.portal-public-view-icon-card-info {
  width: 100%;
  padding: 10px;
  .text-1();
  color: @font-color-1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>