export default {
  symbol: {
    '?': '?',
    ':': ':',
    ',': ',',
    '!': '!'
  },
  common: {
    ok: 'OK',
    cancel: 'Cancel',
    success: 'Success',
    fail: 'Fail',
    uploadImg: 'Upload image',
    theme: 'Theme',
    license: 'License',
    save: 'Save',
    detail: 'Detail',
    copy: 'Copy',
    edit: 'Edit',
    delete: 'Delete',
    remind: 'Remind',
    name: 'Name',
    rename: 'Rename',
    show: 'Show',
    hidden: 'Hidden',
    open: 'Open',
    close: 'Close',
    group: 'Group',
    import: 'Import',
    export: 'Export',
    Select: 'Select All',
    moveOut: 'Move out',
    cancelledDeletion: 'Cancelled deletion',
    paginationTotal: '{num} in total',
    createSuccess: 'Create @:common.success',
    createFail: 'Create @:common.fail',
    uploadSuccess: 'Upload @:common.success',
    uploadFail: 'Upload @:common.fail',
    modifySuccess: 'Modify @:common.success',
    modifyFail: 'Modify @:common.fail',
    copySuccess: '@:common.copy@:common.success',
    copyFail: '@:common.copy@:common.fail',
    enableSuccess: 'Enable @:common.success',
    enableFail: 'Enable @:common.fail',
    deleteSuccess: 'Delete @:common.success',
    deleteFail: 'Delete @:common.fail',
    addSuccess: 'Add @:common.success',
    addFail: 'Add @:common.fail',
  },
  remind: {
    inputRemind: 'Please enter',
    selectRemind: 'Please select',
    searchRemind: 'Enter keyword search',
    selectGroupRemind: 'Please select a group',
    rermanentDeletionRemind: 'This operation will be permanently deleted, whether to continue?',
    passwordRegulationError: 'Please enter any two kinds of letters, numbers, special characters, at least 8 characters!'
  },
  account: 'Account',
  password: "Password",
  signIn: 'Sign in',
  loggingIn: 'Logging in',
  logginErr: 'The username or password is wrong',
  register: 'Register',
  findPassword: 'Find Password',
  accountNullError: 'Please enter your @:account',
  passwordNullError: 'Please enter your @:password',
  scene: 'Scene',
  organization: 'Organization',
  home: 'Home',
  homeMenu: {
    homePage: "Home Page",
    myWorkSpace: "My WorkSpace",
  },
  homeBench: {
    myFlow: "My Flow",
    myCollect: "My Collect",
    recentUse: "Recent use",
    my: "My",
    allWorkSpace: "All Work Space ",
    workSpace: "Work Space",
    app: "App",
    myToDoList: "My To Do List",
    iInitiated: "I initiated",
    completed: "completed",
    createApp: "Create App",
    sort: "Sort",
    cardView: "Card View",
    listView: "List View",
    tableView: "Table View",
  },
  homeBenchRecent: {
    TheLastDay: "The Last Day",
    TheLastWeek: "The Last Week",
    TheLastMonth: "The Last Month"
  },
  userMenu: {
    account: 'User',
    system: 'System',
    logout: 'Logout'
  },
  footMenu: {
    category: 'Sort management',
    store: 'Marketplace',
    theme: 'Theme',
    universe: 'Universe'
  },
  appTypeManagement: 'Application type management',
  appType: 'Application type',
  modifyAppTypeName: 'Modify application type name',
  import: 'Import',
  createScene: 'New scene',
  createWorkSpace: 'New WorkSpace',
  sceneMoreMenu: {
    auth: 'Auth',
    edit: 'Modify',
    acquire: 'Acquire',
    preview: 'Preview',
    export: 'Export',
    delete: 'Delete',
    path: 'Path',
    collect: "Collect"
  },
  sceneShowType: {
    edit: 'Edit',
    published: 'Published',
    unpublish: 'Unpublish',
    table: {
      index: 'Index',
      title: 'Title',
      name: 'Title',
      protocol: 'Protocol',
      username: 'Publish people',
      status: 'Status',
      action: '',
      type: 'Type',
      desc: 'Description',
      url: 'Service address'
    }
  },
  copyPathModal: {
    scenePath: 'Scene Path',
    url: 'URL'
  },
  modifyModal: {
    modifyInfo: 'Modify info',
    orgCategory: 'Org',
    category: 'Categ',
    title: 'Title',
    password: 'Password',
    theme: 'Theme',
    status: 'Publish',
    published: 'Y',
    unpublished: 'N',
    categoryNullError: 'Please select @:modifyModal.category',
    titleNullError: 'Please set @:modifyModal.title',
    spaceTitleNullError: 'Please set @:modifyModal.spaceTitle',
    ThemeIcon: 'Icons and backgrounds',
    spaceTitle: 'Space title',
  },
  accountInfo: {
    safe: 'Safety management',
    phone: 'Phone',
    email: 'E-mail',
    password: 'Password',
    modify: 'Modify',
    unbind: 'Not bound',
    modifyPassword: 'Modify password',
    oldPassword: 'Old password:',
    newPassword: 'New password:',
    confirmPassword: 'Confirm password:',
    oldPasswordNullError: 'Please verify @:accountInfo.oldPassword',
    newPasswordNullError: 'Please verify @:accountInfo.newPassword | The length is 6 to 12 characters',
    confirmPasswordNullError: 'Please verify Password | The length is 6 to 12 characters',
  },
  systemInfo: {
    systemHome: 'Home',
    tenant: 'Tenants',
    apk: 'APK',
    license: 'License',
    dept: 'Departments',
    job: 'Job',
    role: 'Roles',
    user: 'Users',
    theme: 'Themes',
    finance: 'Finance',
    licenseOverdueMessage: 'The License has expired, please reapply!',
    tryOut: 'Try out',
    remain: 'Remain',
    year: '-',
    month: '-',
    day1: 'day',
    day2: '',
    expires: 'Expires',
    logo: 'LOGO',
    uploadLogo: 'Upload',
    uploadImg: 'Upload',
    loginBg: 'Background',
    name: 'Name',
    dominFiling: 'Domain filing',
    light: 'light',
    dark: 'dark',
    addLicense: 'Add lcense',
    serverId: 'Server Id',
    notFoundServer: 'Not found server id',
    activated: 'Activated',
    notActivated: 'Not activated',
    licenseColumns: {
      index: 'Index',
      status: 'Status',
      startTime: 'StartTime',
      endTime: 'EndTime',
    },
    addLicenseRemind: 'Please enter your license',
    addJob: 'Add job',
    jobName: 'job name',
    addDept: 'Add department',
    addSubDept: 'Add branch',
    editDept: 'Edit department',
    deleteDeptRemind: 'Are you sure to delete the department?',
    deptName: 'Name',
    deptHeadUser: 'Dept head user',
    deptInfo: 'Introduce',
    deptState: 'State',
    deptStartTime: 'Start time',
    deptEndTime: 'End time',
    deptLon: 'Longitude',
    deptlat: 'Latitude',
    deptTable: {
      index: 'Index',
      userName: 'Account',
      userAlia: 'Username',
      userRole: 'Role',
      userState: 'State',
      action: ''
    },
    roleInfo: {
      addRole: 'Add role',
      edit: 'Modify',
      delete: 'Delete',
      deleteRemind: 'This operation will be permanently deleted. Do you want to continue?',
      index: 'Index',
      roleName: 'Role name',
      userSize: 'User number',
      action: '',
    },
    userInfo: {
      batchAddUsers: 'Batch add users',
      addUser: 'Add user',
      modifyUserInformation: 'Modifying user information',
      edit: 'modify',
      reset: 'Reset password',
      delete: 'Delete',
      index: 'Index',
      userName: 'Account',
      password: 'Password',
      userAlia: 'Username',
      userDeptNames: 'Department',
      userRole: 'Role',
      jobs: 'Jobs',
      userJobs: 'Jobs',
      action: '',
      downloadTemplate: 'Download template',
      downloadTmeplateRemind: 'Download , modify and import the template',
      downloadRemind: 'Drag the file here, or ',
      clickUpload: 'Click to upload',
      selectDeptRemind: 'Select department',
      userNameNullError: '@:remind.selectRemind @:systemInfo.userInfo.userName',
      passwordNullError: '@:remind.selectRemind @:systemInfo.userInfo.password',
      userAliaNullError: '@:remind.selectRemind @:systemInfo.userInfo.userAlia',
      userRoleNullError: '@:remind.selectRemind @:systemInfo.userInfo.userRole',
      userStateNullError: '@:remind.selectRemind @:systemInfo.userInfo.userState',
      userDeptsNullError: '@:remind.selectRemind @:systemInfo.userInfo.userRole',
      addDeptRemind: 'Please add departments first!',
      resetPasswordRemind: 'This operation will reset the user password. Do you want to continue?',
      resetPasswordSuccessRemind: 'The password is reset to',
      resetPasswordFailRemind: 'Reset fail',
      cancelledResetPasswordRemind: 'Reset password has been cancelled'
    },
    themeInfo: {
      theme: 'Theme',
      layout: 'Layout',
      themeSetting: 'Theme Setting',
      page: 'Page',
      pageHeader: 'Page header',
      pageTheme: 'Page theme',
      table: 'Table',
      card: 'Card',
      customPage: 'Custom page',
      customPageChart: 'Custom page chart',
      otherSetting: 'Other'
    }
  },
  selectUserModal: {
    title: 'Select personnel',
    placeholder: 'Please enter the platform name'
  },
  selectDepartmentModal: {
    title: 'Select Department',
    placeholder: 'Please enter the platform name'
  },
  auth: {
    Description: 'You are the administrator of this application and can configure the application, roles, and members',
    getAppID: 'Get app ID',
    title: 'Application Administrator',
    desc: 'Configure applications, Manage data and person',
    unit: 'people',
    AppAdministrator: 'Administrators',
    Owner: 'Owner',
    OtherRole: 'Role',
    PageRole: 'Quick login',
    RolePermissionSettings: 'Role permission settings',
    addRole: 'Add role',
    updateRole: 'Update role',
    authInfo: {
      deleteRemind: 'This operation will remove these users. Do you want to continue?',
      renewSuccess: 'update @:common.success',
      renewFail: 'update @:common.fail',
      copyRemindSuccess: 'Copied!',
      copyRemindFail: 'Copy failed!',
    },
    // 管理员
    adminRole: {
      PersonnelRemoval: 'Personnel removal',
      Department: 'Add Department',
      AddPeople: 'Add people'
    },
    adminRoleTalbe: {
      index: 'Index/Select All',
      name: 'name',
      role: 'ROLE',
      type: 'type',
      department: 'department',
      addTime: 'Joined on',
      operation: ''
    },
    typeCollection: {
      user: 'user',
      department: 'department'
    },
    // 角色
    role: {
      title: '+ Add Role',
      desc: 'Add Role Settings Application Permissions,Control applications visible to users',
    },
    roleTalbe: {
      roleName: 'name',
      desc: 'describe',
      userNum: 'Number of personnel',
      operation: '',
    },
    // 快捷登录
    quickLogin: {
      title: 'Quick login',
      desc: 'Manage people who use this application as a quick login page',
      PersonnelRemoval: 'Personnel removal',
      Department: 'Add department',
      AddPeople: 'Add people'
    },
    quickLoginTalbe: {
      index: 'Index/Select All',
      name: 'name',
      type: 'type',
      department: 'department',
      addTime: 'Joined on',
      operation: ''
    },
    copyAppId: {
      title: 'Application ID',
      name: 'Application id',
      copy: 'copy'
    },
    moreMenuList: {
      edit: 'modify',
      CopyUser: 'Replication Role',
      roleUsers: 'Viewing personnel',
      addUser: 'Add people',
      addDepartment: 'Add Department',
      delete: 'delete'
    },
    appRoleAuthorityModal: {
      name: 'name',
      desc: 'describe',
      permissions: 'Application permissions',
      gateway: 'Portal :',
      formDesign: 'Form Design :',
      AllForms: 'All form permissions',
      SelectableForms: 'Selectable form permissions'
    },
    sceneRoleAuthorityModal: {
      name: 'name',
      desc: 'describe',
      permissions: 'Application permissions',
      gateway: 'Portal :',
      build: 'building :',
      Modeling: 'Scene modeling :',
      develop: 'onlineDevelopment :'
    },
    fileRoleAuthorityModal: {
      name: 'name',
      desc: 'describe',
      permissions: 'Application permissions',
      gateway: 'Portal :',
      build: 'building :',
      fileJurisdiction: 'file permission :'
    }
  },
  globalSearch: {
    placeholder: 'Search...',
    noData: 'no data',
    unfold: 'unfold',
    fold: 'fold',
  },
  appStore: {
    title: 'AppStore',
    install: 'Install',
    RequestFailed: 'Request failed',
    ConfirmInstallation: 'Do you confirm the installation?',
    SuccessfullyInstalledTo: 'Successfully installed to',
    DefaultClassification: 'default classification',
    InstallationFailure: 'Installation failed. Please contact the administrator!',
    NoPermissions: 'No permission, please contact the administrator!',
  }
}